<template>
  <div class="contentDiv">
    <p class="headerCon">
      2022 China (Ningbo) Export Cross-border E-commerce Expo and Global Home
      Furnishing Brand Festival
    </p>
    <div class="contentText">
    
      <span class="contentTitle">1. Introduction to the exhibition</span>
      <p>
        2022 China (Ningbo) Export Cross-border E-commerce Expo and Global Home
        Furnishing Brand Festival, as "the first cross-border exhibition in East
        China", is currently the most appealing cross-border high-end
        professional exhibition in East China, and it is also the only "big
        home" in China. A cross-border e-commerce expo for the core category.
        Based on the "East China Industrial Belt", the expo focuses on the "big
        home" category, integrates the core factories of the most popular
        categories of cross-border e-commerce, and builds the best service
        ecology for cross-border e-commerce. At the same time, the exhibition
        shoulders the mission of transforming traditional export trade, and
        actively invites a large number of foreign trade companies in the
        Yangtze River Delta region and across the country to purchase on the
        spot to meet the traditional foreign trade companies' demand for
        diversified export commodities and large-scale procurement. The expo
        will be held from August 9-11, 2022, with an exhibition area of 60,000
        square meters. The exhibition location is in Halls 1-7 of Ningbo
        International Convention and Exhibition Center. At that time, 2000+
        foreign trade factories from high-quality industrial belts across the
        country will participate in the exhibition, and 50,000 professional
        visitors from all over the country will come to purchase.
      </p>

      <span class="contentTitle">2.the previous situation： </span>
      <p>
        Last year's Cross Expo first became a "popular". With the theme of
        "foreign trade procurement, cross-border product selection, factory
        transformation, and industry trends", the exhibition received 31,200
        professional visitors in 3 days, with 90.5% of the audience's on-site
        satisfaction and 52% of exhibitors' intention to renew, successfully
        refreshing Ningbo's new Exhibition history. The exhibition helps
        enterprises in the industry to open up the production end, trade end,
        and brand end, promote resource docking, realize chain development, and
        promote the construction of cross-border e-commerce ecology. Through the
        accumulation of cross-border e-commerce resources across the country, it
        has brought great attention to the cross-border e-commerce industry in
        Zhejiang and even East China.
      </p>

      <span class="contentTitle">3. Organizational Structure</span>
      <p>Directed by：浙江省商务厅、宁波市人民政府</p>
      <p>Organizer：宁波市商务局、宁波市贸促会、宁波文旅集团</p>
      <p>co-host：浙江省贸促会</p>
      <p>Support Units：宁波市工商业联合会、宁波市对口支援和区域合作局</p>
      <p>
        Organizer：宁波欧德国际商务咨询服务有限公司、宁波市跨境电子商务协会、宁波市家居产业协会、宁波市服装协会、AiLa
      </p>

      <span class="contentTitle">4. The scale of the exhibition</span>
      <p>
        The exhibition area is 60,000 square meters, a year-on-year increase of
        200%. At that time, 2,000 foreign trade factories from high-quality
        industrial belts across the country will participate in the exhibition,
        and 50,000 professional visitors from all over the country will come to
        purchase.
      </p>
      <p>
        (1) 2000+ foreign trade factories: Covering the national high-quality
        industrial belt, differentiated factories have become the main force.
        More than 200 "specialized, special, excellent and new", "little giants"
        and high-tech enterprises in this year's exhibitors have a strong
        lineup. They come from five exhibiting categories and more than 30
        industrial clusters. They are the leading factories in their respective
        fields. These high-quality suppliers with stronger R&D capabilities,
        patent accumulation and product differentiation advantages will become
        the focus of the super-sellers.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="less">
.contentDiv {
  max-width: 1300px;
  margin: 0 auto;
  padding: 20px 0 20px;
  .headerCon {
    text-align: center;
    font-size: 40px;
    font-weight: 400;
    margin: 20px 0;
  }
}
.contentText {
  text-indent: 2em;
  font-size: 20px;
  line-height: 40px;
}
.contentTitle {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0;
  display: block;
  text-indent: 0em;
}
.textCenter {
  font-weight: 900;
}
.headerTop {
  display: inline-block;
  margin-bottom: 20px;
  text-indent: 0em;
  font-weight: 500;
}
</style>
